import APIResponse from '../../../core/models/APIResponse';
import {Services} from '../../../core/services/Services';
import MessageList from '../../../core/utils/MessageList';
import PricingTier from '../../../core/utils/PricingTier';
import PriceQuote from './PriceQuote';
import ButtonProcessingState from '../../../core/utils/ButtonProcessingState';
import {clamp, isEmptyString} from '../../../core/utils/utils';
import ProductServiceAbstract from './ProductServiceAbstract';
import {ConfigurableProductAttributeAbstract} from '../../product/models/abstract/ProductAttributeAbstract';
import {ConfiguredProductAbstract} from '../../svgcustom/models/abstract/ConfiguredProductAbstract';
import {Color} from '../../svgcustom/models/Color';
import {IDeferred, IPromise} from '../../../core/utils/SimplePromise';
import ConfiguredRetailPackage from '../../packaging/models/ConfiguredRetailPackage';
import {RetailPackage} from '../../packaging/models/RetailPackage';
import {Collection} from '../../../core/models/Collection';
import {ColorMap} from '../../svgcustom/models/ColorMap';
import {DisplayTypeEnum, ProductTypeEnum} from '../../svgcustom/models/enums';
import AbstractProductOption from '../../svgcustom/models/AbstractProductOption';
import {ProductAbstract} from '../../svgcustom/models/abstract/ProductAbstract';
import {FontService} from '../../svgcustom/services/FontService';

/*
    Configured Product Service Abstract

    This is a abstract class that defines all shared functionality for any product that has a base and configured class.
    This applies to:
        Custom/Template
        ConfiguredClipartProduct/ClipartProduct

    This should only deal with the models not paperjs or any canvas/rendering service.
 */

export default abstract class ConfiguredProductServiceAbstract extends ProductServiceAbstract {
    public override base: ConfigurableProductAttributeAbstract;
    public override configured: ConfiguredProductAbstract;

    public override base_class: typeof ConfigurableProductAttributeAbstract;
    public override configured_class: typeof ConfiguredProductAbstract;

    public override setup_complete: IPromise<void>;
    protected override setup_complete_deferred: IDeferred<void>;

    public proportions_ratio: number;

    public price_tier: PricingTier;
    public pricing: PricingTier[];

    private price_quote_uuid: string;
    private price_quote_timeout;

    private available_packages: any[] = [];
    private packages: APIResponse<RetailPackage>;

    abstract configured_option_model: string;

    constructor(base_class, configured_class, base_key) {
        super(base_class, configured_class, base_key);
    }

    override get optionsPrice(): number {
        return this.configured.calcOptionsPrice(this.price.price, this.show_retail);
    }

    override setupFromData(base_data, configured_data, all_data): void {
        let base = null;
        let configured = null;

        if (base_data) {
            base = new (this.base_class as any)(base_data);
        }
        if (configured_data) {
            configured = new this.configured_class(configured_data);
        }

        if (!base && configured) {
            base = configured[this.base_key];
        }
        if (!configured) {
            configured = new this.configured_class();
            configured.currency = all_data.currency;
        }

        this.currency = all_data.currency;

        this.setupExtraData(all_data);
        this.setupFromProduct(base, configured);
    }

    override setupExtraData(all_data): void {}

    override setupFromProduct(base, configured): void {
        this.base = base;
        this.configured = configured;

        this.setupConfiguredFromBase();
    }

    override nextUrl() {
        return this.configured.getNextURL();
    }

    get show_wholesale_prices(): boolean {
        return false;
    }

    get color_map(): ColorMap {
        if (this.configured.material_color && this.configured.material?.color_maps?.length) {
            return this.configured.material.color_maps.find(v => v.key.id == this.configured.material_color.id);
        }

        return null;
    }

    get product_color_set(): Collection<Color> {
        if (this.color_map) {
            return this.color_map.values;
        }

        return this.configured.material.design_colors;
    }

    get colors_available() {
        if (this.color_map) {
            return this.color_map.values;
        }

        return this.configured.material.design_colors;
    }

    get colors_available_for_material() {
        return this.configured.material.material_colors;
    }

    /*
        This will create a new product and start the setup process
     */
    createNewFromBase(): void {
        this.configured = new this.configured_class();

        this.setupConfiguredFromBase();
    }

    /*
        Setup and validate options on the configured class.
     */
    override setupConfiguredFromBase(needs_additional_setup=false): void {
        super.setupConfiguredFromBase(needs_additional_setup);

        // Make sure we are using the same object and its fully built
        this.configured[this.base_key] = this.base;

        // First check if the material is no longer valid
        let material = this.configured.material;
        if (material && !this.base.materials.getItemFromID(this.configured.material.id)) {
            if (this.configured.material.name) {
                this.errors.add('material', `The material ${material.name} is no longer available for this product and has been reverted to the default.`);
            }
            material = null;
        }

        // Validate that a default material is set and that it's an option that is available
        if (!material && this.base.default_material) {
            material = this.base.materials.getItemFromURI(this.base.default_material);
        }
        if (!material) {
            material = this.base.materials[0];
        }

        // Make sure the material object is the same one in the list so equals evaluation will work
        this.configured.material = this.base.materials.getItemFromID(material.id);
        this.configured.type = this.base.type;

        // Validate size
        if (!this.configured.width) {
            if (this.base.default_width) {
                this.configured.width = this.base.default_width;
            }
        }
        if (!this.configured.height) {
            if (this.base.default_height) {
                this.configured.height = this.base.default_height;
            }
        }

        // If no minimum or default quantity was defined, set it to 1
        if (!this.base.minimum_quantity) {
            this.base.minimum_quantity = 1;
        }
        if (!this.base.default_quantity) {
            this.base.default_quantity = 1;
        }

        // Validate the quantity from the configured product
        if (!this.configured.quantity) {
            this.configured.quantity = this.base.default_quantity;
        }
        if (this.configured.quantity < this.base.minimum_quantity) {
            this.errors.add('quantity', `The minimum quantity for this product is ${this.base.minimum_quantity}`);
            this.configured.quantity = this.base.minimum_quantity;
        }

        this.setupProductOptions();

        // Setup watch bindings
        this.configured.bind('change:material', this.updatePricing.bind(this));
        this.configured.bind('change:material', this.checkMaterialColor.bind(this));
        this.configured.bind('change:material', this.checkBackorderedMaterial.bind(this));
        this.configured.bind('change:material_color', this.validateMaxSize.bind(this));

        this.checkMaterialColor();

        this.updatePricing(true);
        this.checkWarnings();
        this.checkBackorderedMaterial();

        this.configured.bind('change:width', () => {
            this.updatePricing();
        });
        this.configured.bind('change:height', () => {
            this.updatePricing();
        });
        this.configured.bind('change:quantity', this.checkWarnings.bind(this));

        if (this.base.limited_quantity) {
            this.configured.quantity = this.base.limited_quantity;
        }

        if (!needs_additional_setup) {
            this.setup_complete_deferred.resolve();
        }
    }

    checkBackorderedMaterial() {
        if (!this.warnings) {
            this.warnings = new MessageList();
        }

        if (this.configured.material.backordered) {
            this.warnings.add('material', 'This material is out of stock and is on backorder. Please contact customer service for an estimated ship date.')
        }
        else {
            this.warnings.remove('material')
        }
    }

    checkMaterialColor() {
        if (!this.configured.material_color || !this.configured.material.design_colors.getItemFromID(this.configured.material_color.id)) {
            this.configured.material_color = this.configured.material.design_colors.find(v => v.default) || this.configured.material.design_colors[0];
        }
    }

    async setupRetailPackaging() {
        await this.setup_complete;

        // Already setup, don't call again to prevent duplicating listeners
        if (this.packages) {
            await this.packages.$promise;
            return;
        }

        this.resetPackages();
        await this.packages.$promise;

        this.bind('change:size', this.updatePackages.bind(this));
        this.configured.bind('change:material', this.resetPackages.bind(this));
    }

    private resetPackages() {
        this.packages = Services.get<typeof RetailPackage>('RetailPackage').objects.filter({material: this.configured.material.id});
        this.available_packages = [];

        this.packages.$promise.then(() => {
            this.updatePackages();
            this.trigger('sync');
        })
    }

    hasPackageOptions() {
        return this.packages && this.packages.items.length > 0;
    }

    updatePackages() {
        let old_packages = this.available_packages;

        this.available_packages = [];
        for (const item of this.packages.items) {
            let size = item.getValidSizeFor(this.width, this.height);

            if (!size) {
                continue;
            }

            let configured;
            if (this.configured.retail_package && this.configured.retail_package.package.id == item.id) {
                configured = this.configured.retail_package;
                configured.size = size;
            }
            else {
                for (const old_package of old_packages) {
                    if (old_package.package.id == item.id) {
                        configured = old_package
                        if (old_package.size != size) {
                            old_package.size = size;
                        }
                        break;
                    }
                }
            }
            if (!configured) {
                configured = new ConfiguredRetailPackage({
                    package: item,
                    size: size
                })
            }

            configured.fetchQuote();
            this.available_packages.push(configured);
        }
    }

    validateMaxSize() {
        if (!this.errors) {
            this.errors = new MessageList();
        }
        this.errors.remove('size');

        let errors = false;
        let skip_material_check = this.configured.material_color && this.configured.material_color.sizeIsRestricted();
        if (!skip_material_check) {
            if (!this.configured.material.sizeIsValid(this.configured.width, this.configured.height)) {
                this.errors.merge(this.configured.material.errors.list);
                errors = true;
            }
        }
        else {
            if (!this.configured.material_color.sizeIsValid(this.configured.width, this.configured.height)) {
                this.errors.merge(this.configured.material_color.errors.list);
                errors = true;
            }
        }
        this.trigger('sync');
        return !errors;
    }

    updatePricing(keep_errors?): void {
        if (!keep_errors) {
            this.errors.reset();
        }

        if (!this.configured.material || !this.width || !this.height || !this.configured.material.quantityIsValid(this.configured.quantity)) {
            return;
        }

        if (!this.validateMaxSize()) {
            return;
        }

        const quoted_mateiral = this.configured.material;
        const quoted_width = this.configured.cleaned.width;
        const quoted_height = this.configured.cleaned.height;

        const PriceQuoteService = Services.get<PriceQuote>('PriceQuote');

        this.price_quote_uuid = PriceQuoteService.uuid();
        this.price_tier = null;
        PriceQuoteService.getPriceQuote(
            quoted_width,
            quoted_height,
            quoted_mateiral.id,
            this.price_quote_uuid,
            this.show_retail
        ).then((response) => {
            // Only accept pricing data from the last submitted request
            if (response.data.uuid != this.price_quote_uuid) {
                return;
            }

            let prices = response.data.prices;
            if (!prices) {
                return;
            }

            let quantities = response.data.quantities;
            quantities.unshift(1);

            // shift sq inches breakpoint
            const sqInch = prices.shift();
            if (sqInch < this.configured.sqInches) {
                return;
            }

            this.pricing = [];
            for (let i = 0; i < prices.length; i++) {
                this.pricing.push(new PricingTier(quantities[i], prices[i]));
            }

            // Reset priceTier
            this.price_tier = null;
            this.price;
            this.trigger('change:price');
        }, (response) => {
            this.errors.reset();

            if (response && response['data']) {
                this.errors.merge(new MessageList(response['data']['errors']).list);
            }

            return response;
        });
    }

    get price(): PricingTier {
        if (this.price_tier)
            return this.price_tier;

        // Can't calculate the price without pricing list
        if (!this.pricing) {
            return new PricingTier(0, 0);
        }

        let tier = this.pricing[0];
        for (const t of this.pricing) {
            if (t.quantity <= parseInt('' + this.configured.quantity)) {
                tier = t;
            }
        }

        if (this.configured.variable_data) {
            this.price_tier = new PricingTier(this.configured.quantity, tier.unitPrice * (this.configured.material.variable_data_charge + 1));
        }
        else {
            this.price_tier = new PricingTier(this.configured.quantity, tier.unitPrice);
        }

        return this.price_tier;
    }

    override async preSave() {
        if (this.configured.retail_package && !this.configured.retail_package.id) {
            await this.configured.retail_package.save();
        }
    }

    override checkWarnings(): void {
        super.checkWarnings();

        if (this.base.remaining_stock != null) {
            if (this.quantity > this.base.remaining_stock) {
                if (this.base.allow_backorders) {
                    this.warnings.add('quantity', `This product is on backorder and only ${this.base.remaining_stock} are left in stock.`);
                }
                else if (this.outOfStock()) {
                    if (this.base.remaining_stock <= 0) {
                        this.warnings.add('quantity', 'This product is out of stock.')
                    }
                    else {
                        this.warnings.add('quantity', `This product only has ${this.base.remaining_stock} left in stock.`);
                    }
                }
            }
        }
    }

    public outOfStock(): boolean {
        return this.base.remaining_stock != null && !this.base.allow_backorders && this.quantity > this.base.remaining_stock;
    }

    public disabled(): boolean {
        return !this.setup_complete.isResolved() || this.outOfStock();
    }

    async save($event?, skip_validation?) {
        const state = new ButtonProcessingState($event);
        state.process();

        // Remove any save before exit warnings
        window.onbeforeunload = null;

        if (!skip_validation) {
            this.validate();
            if (this.hasErrors) {
                state.resolved();
                return;
            }
        }

        await this.preSave();

        // When we're saving, we need to make sure there aren't any more
        // changes to the design.
        this.saving = true;

        this.configured.save().then((response) => {
            this.onSaveSuccess(response);

            if (this.add_to_cart) {
                this.configured.addToCart().then(() => {
                    this.saving = this.pageTransition(response);
                    state.resolved();
                }, (response: any) => {
                    if (response.data && response.data.errors) {
                        this.errors.merge(response.data.errors);
                    }
                    else if (response.data && response.data.error) {
                        this.errors.add('__all__', response.data.error)
                    }
                    else {
                        this.errors.add('__all__', 'Unable to add the product to the cart');
                    }

                    state.resolved();
                    this.saving = false;
                    return response;
                });
            }
            else {
                this.saving = this.pageTransition(response);
                state.resolved();
            }
        }, (error: any) => {
            let response = error;
            if (error.response) {
                response = error.response;
            }

            this.saving = false;
            state.resolved();

            // We really need to standardize these...
            if (response.data && response.data.errors) {
                this.errors.merge(response.data.errors);
            }
            if (response.data && response.data.error) {
                this.errors.add('__all__', response.data.error);
            }
            if (response.data && response.data.error_message) {
                this.errors.add('__all__', response.data.error_message);
            }

            this.errors.merge(this.configured.errors.list);

            this.onSaveFailure(response);
        });
    }

    get allow_custom_sizes(): boolean {
        return true
    }
    
    get show_custom_sizes(): boolean {
        return true;
    }

    /*
        Optional size bindings to add additional functionality for size change checks.
        It's used with the vinyl lettering tool for the font lock functionality.
     */
    protected onHeightChange() {}
    protected onWidthChange() {}

    get height(): number {
        return this.configured.height;
    }
    set height(height) {
        if (height === this.configured.height)
            return;

        this.price_tier = null;

        // Don't clamp the configured height and width because we need max precision
        this.configured.height = height;

        // Lock the ratio if lock_ratio is set and the users are not locked to a set size
        if (this.proportions_ratio || this.base.hasLockRatio) {
            this.configured.width = this.configured.height * (this.proportions_ratio || this.base.lockRatio);
        }
        this.onHeightChange();

        this.trigger('change:height');
        this.trigger('change:size');
    }

    get width(): number {
        return this.configured.width;
    }
    set width(width) {
        if (width === this.configured.width) {
            return;
        }

        this.price_tier = null;

        // Don't clamp the configured height and width because we need max precision for converted values
        this.configured.width = width;

        // Lock the ratio if lock_ratio is set and the users are not locked to a set size
        if (this.proportions_ratio || this.base.hasLockRatio) {
            this.configured.height = this.configured.width / (this.proportions_ratio || this.base.lockRatio);
        }
        this.onWidthChange();

        this.trigger('change:width');
        this.trigger('change:size');
    }

    /*
        Unit converted height and width access based on user preferences. This should be used instead of the normal
        width and height. We must check for the empty string case differently without converting the value to allow
        for clearing out the input without it being forced back to 0.
     */
    get unit_width() {
        if (isEmptyString(this.width)) {
            return this.width;
        }

        return clamp(this.ups.convertSizeFromInches(this.width));
    }
    set unit_width(v) {
        if (isEmptyString(v)) {
            this.width = v;
        }
        else {
            this.width = this.ups.convertSizeToInches(v);
        }
    }

    get unit_height() {
        if (isEmptyString(this.height)) {
            return this.height;
        }

        return clamp(this.ups.convertSizeFromInches(this.height));
    }
    set unit_height(v) {
        if (isEmptyString(v)) {
            this.height = v;
        }
        else {
            this.height = this.ups.convertSizeToInches(v);
        }
    }

    get quantity(): number {
        return this.configured.quantity;
    }

    set quantity(v) {
        this.configured.quantity = v;

        this.updatePricing();
        this.trigger('change:quantity');
    }

    toggleLockedProportions() {
        if (!this.allow_custom_sizes) {
            return;
        }

        if (this.proportions_ratio) {
            this.proportions_ratio = null;
        }
        else {
            this.proportions_ratio = this.width / this.height;
        }
    }

    setLockedProportions(v) {
        if (!this.allow_custom_sizes) {
            return;
        }
        if (v) {
            this.proportions_ratio = this.width / this.height;
        }
        else {
            this.proportions_ratio = null;
        }
    }

    updateCanvas() {}

    isPromotionalTextDisabled(): boolean {
        if (!this.setup_complete.isResolved()) {
            return false;
        }

        return !!this.configured.material.disable_promotional_text;
    }

    adjustSizeToPackage() {
        if (this.packages.items.length == 0) {
            return;
        }

        let item = this.packages.items[0];

        if (item.available_sizes.length == 0) {
            return;
        }

        let size = item.available_sizes[0];

        if (!this.proportions_ratio) {
            this.proportions_ratio = this.width / this.height;
        }

        if (this.width > this.height) {
            this.width = size.max_product_width - 0.25;
        }
        else {
            this.height = size.max_product_width - 0.25;
        }
    }

    adjustedPackageSize() {
        // Used to show a preview of what the size will be adjusted to.
        // Use adjustSizeToPackage to adjust the size of the product to the package.

        if (!this.packages || this.packages.items.length == 0) {
            return;
        }

        let item = this.packages.items[0];

        if (item.available_sizes.length == 0) {
            return;
        }

        let size = item.available_sizes[0];

        let proportions_ratio = this.proportions_ratio || this.width / this.height;
        if (this.width > this.height) {
            return {
                width: Number(size.max_product_width - 0.25).toFixed(2),
                height: Number((size.max_product_width - 0.25) * (1 / proportions_ratio)).toFixed(2)
            }
        }
        else {
            return {
                width: Number((size.max_product_width - 0.25) * proportions_ratio).toFixed(2),
                height: Number(size.max_product_width - 0.25).toFixed(2),
            }
        }
    }


    materialSelectionMap() {
        let map = {};

        for (const material of this.base.materials) {
            if (!material.adhesive) {
                continue;
            }

            let name = material.material_name || material.name;
            if (!map[name]) {
                map[name] = {}
            }
            map[name][material.adhesive] = material;
        }

        return map;
    }

    materialTypes() {
        return Object.keys(this.materialSelectionMap()).sort();
    }

    adhesiveTypes() {
        let data = this.materialSelectionMap()[this.material_name] || [];
        return Object.keys(data).sort((a, b) => {
            return data[a].order - data[b].order;
        });
    }

    get material_name() {
        return this.configured.material.material_name || this.configured.material.name;
    }
    set material_name(v) {
        let map = this.materialSelectionMap();
        this.configured.material = map[v][Object.keys(map[v])[0]];
    }

    get adhesive_type() {
        return this.configured.material.adhesive;
    }
    set adhesive_type(v) {
        let map = this.materialSelectionMap();
        this.configured.material = map[this.material_name][v];
    }

    setupProductOptions() {
        if (!this.configured_option_model) {
            return;
        }

        // Unify the template and custom product's option sets
        const ConfiguredOptionModel = Services.get<typeof AbstractProductOption>(this.configured_option_model);

        for (const option_set of (this.base as ProductAbstract).options) {
            // I have no idea what is causing this but somehow customers are getting empty option sets added
            if (!option_set || !option_set.id) {
                continue;
            }

            let found = false;
            for (const custom_option of this.configured.options) {
                if (option_set.equals(custom_option.option_set)) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                let default_option = null;

                if (option_set.display_type != DisplayTypeEnum.CHECKBOX) {
                    default_option = option_set.options[0];
                    for (const option of option_set.options) {
                        if (option.default) {
                            default_option = option;
                            break;
                        }
                    }
                }

                // Ugh... There isn't a good way to define a concrete class that implements an abstract class and is not the abstract class
                // @ts-ignore "Cannot create an instance of an abstract class."
                const option = new ConfiguredOptionModel({
                    option_set: option_set,
                    option: default_option,
                    configured: this.configured
                });

                this.configured.options.push(option);
            }
        }

        // Make sure the option selected is form the option set.
        // Otherwise, vue won't see them as the same item and option selections won't work until they interact with it.
        for (let option of this.configured.options) {
            if (option.option_set && option.option && option.option_set.options.length > 0) {
                option.option = option.option_set.options.getItemFromID(option.option.id) as any || option;
            }

            option.option_set.validateDisplayType();
        }
    }

    proportionsLocked() {
        return this.proportions_ratio != null;
    }
}
